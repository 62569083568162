import React, { useRef, useState, useEffect, useCallback } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Dropdown, IDropdownOption } from "@fluentui/react";
import readNDJSONStream from "ndjson-readablestream";

import styles from "./Chat.module.css";

import { chatApi, getHeaders, RetrievalMode, ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, ResponseMessage, VectorFieldOptions, GPT4VInput } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
// import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { useLogin, getToken } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { TokenClaimsDisplay } from "../../components/TokenClaimsDisplay";
import { LoginButton } from "../../components/LoginButton";
import useSWR, { mutate } from "swr";
import Loader from "../../components/Loader/Loader";

import { addChat, clearChat } from "../../redux/slice/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { clearChat as clearChatAction } from "../../redux/slice/chatSlice";

import { ExampleList } from "../../components/Example";
import ReferenceIndexes from "../../components/ReferenceIndexes/ReferenceIndexes";

interface FeedbackSentStatus {
    [key: string]: boolean;
}

const Chat = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const userName = activeAccount ? activeAccount.name : null;

    const dispatch = useDispatch();
    const chats = useSelector((state: RootState) => state.chat);

    const referenceIndexesItems = [
        {
            id: "1",
            indexName: "gptkbindex",
            displayName: "すべて"
        },
        {
            id: "2",
            indexName: "company_rules_index",
            displayName: "社内規則"
        },
        {
            id: "3",
            indexName: "management_systems_index",
            displayName: "マネジメント文書"
        },
        {
            id: "4",
            indexName: "manufacturing_standards_index",
            displayName: "製造基準書"
        },
        {
            id: "5",
            indexName: "process_capacities_index",
            displayName: "工程能力"
        },
        {
            id: "6",
            indexName: "delivery_specs_index",
            displayName: "納入仕様書"
        }
    ];

    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [temperature, setTemperature] = useState<number>(0.3)
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [shouldStream, setShouldStream] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(true);
    const [useOidSecurityFilter, setUseOidSecurityFilter] = useState<boolean>(false);
    const [useGroupsSecurityFilter, setUseGroupsSecurityFilter] = useState<boolean>(false);
    const [vectorFieldList, setVectorFieldList] = useState<VectorFieldOptions[]>([VectorFieldOptions.Embedding])
    const [gpt4vInput, setGPT4VInput] = useState<GPT4VInput>(GPT4VInput.TextAndImages)
    const [referenceIndex, setReferenceIndex] = useState<string>(referenceIndexesItems[0].indexName);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [latestAnswerId, setLatestAnswerId] = useState<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse, uuid: string][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse, uuid: string][]>([]);

    const [analysisPanelIsOpen, setAnalysisPanelIsOpen] = useState<boolean>(false);

    const [feedbackSentStatus, setFeedbackSentStatus] = useState<FeedbackSentStatus>({});

    const [isExampleListOpen, setIsExampleListOpen] = useState<boolean>(true);

    const handleAsyncRequest = async (
        question: string,
        answers: [string, ChatAppResponse, string][],
        responseBody: ReadableStream<any>
    ) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;
        let openai_uuid: string = "";

        const updateState = (newContent: string) => {
            return new Promise(resolve => {
                setTimeout(() => {
                    answer += newContent;
                    const latestResponse: ChatAppResponse = {
                        ...askResponse,
                        message: { content: answer, role: askResponse.message.role }
                    };
                    setStreamedAnswers([...answers, [question, latestResponse, openai_uuid]]);
                    resolve(null);
                }, 33);
            });
        };
        try {
            setIsStreaming(true);
            for await (const event of readNDJSONStream(responseBody)) {
                if (event["context"] && event["context"]["data_points"]) {
                    event["message"] = event["delta"];
                    askResponse = event as ChatAppResponse;
                    openai_uuid = event["id"];
                } else if (event["delta"]["content"]) {
                    openai_uuid = event["id"];
                    setIsLoading(false);
                    await updateState(event["delta"]["content"]);
                } else if (event["context"]) {
                    openai_uuid = event["id"];
                    askResponse.context = { ...askResponse.context, ...event["context"] };
                } else if (event["error"]) {
                    throw Error(event["error"]);
                }
            }
        } finally {
            setIsStreaming(false);
        }

        const fullResponse: [string, ChatAppResponse, string] = [
            question,
            {
                ...askResponse,
                message: { content: answer, role: askResponse.message.role }
            },
            openai_uuid
        ];
        return fullResponse;
    };

    const client = useLogin ? useMsal().instance : undefined;

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setStreamedAnswers([]);

        const token = client ? await getToken(client) : undefined;

        try {
            const messages: ResponseMessage[] = answers.flatMap(a => [
                { content: a[0], role: "user" },
                { content: a[1].message.content, role: "assistant" }
            ]);

            const request: ChatAppRequest = {
                index_name: referenceIndex,
                gpt_deployment: "gpt-4o-mini",
                messages: [...messages, { content: question, role: "user" }],
                stream: shouldStream,
                context: {
                    overrides: {
                        top: retrieveCount,
                        temperature: temperature,
                        retrieval_mode: retrievalMode,
                        semantic_ranker: true,
                        semantic_captions: true,
                        suggest_followup_questions: true,
                        use_oid_security_filter: useOidSecurityFilter,
                        use_groups_security_filter: useGroupsSecurityFilter,
                        use_gpt4v: false,
                        gpt4v_input: gpt4vInput,
                        },
                    },
                // ChatAppProtocol: Client must pass on any session state received from the server
                session_state: answers.length ? answers[answers.length - 1][1].session_state : null
            };

            const response = await chatApi(request, token?.accessToken);
            if (!response.body) {
                throw Error("No response body");
            }
            if (shouldStream) {
                const [q, parsedResponse, uuid] = await handleAsyncRequest(question, answers, response.body);
                dispatch(addChat([q, parsedResponse, uuid]));
                setLatestAnswerId(uuid);
            } else {
                const parsedResponse: ChatAppResponseOrError = await response.json();

                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "不明なエラー");
                }

                const uuid = parsedResponse.choices?.[0]?.openai_uuid ?? "";
                setLatestAnswerId(uuid);

                if (parsedResponse.choices && parsedResponse.choices.length > 0) {
                    dispatch(addChat([question, parsedResponse as ChatAppResponse, uuid]));
                    setAnswers([...answers, [question, parsedResponse as ChatAppResponse, uuid]]);
                }
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
            mutate("/getusedtoken");
        }
    };

    const fetcher = async () => {
        const token = client ? await getToken(client) : undefined;
        if (token == undefined) {
            throw new Error("Token is undefined");
        } else {
            return fetch("/getusedtoken", {
                method: "GET",
                headers: getHeaders(token ? token.accessToken : undefined)
            }).then(res => res.json());
        }
    };

    const { data: usedTokens, error: gettingTokenError, isLoading: gettingToken } = useSWR("/getusedtoken", fetcher);
    const errorOrLoading = gettingToken || gettingTokenError;

    const updateFeedbackSentStatus = (uuid: string, sent: boolean) => {
        setFeedbackSentStatus(prev => ({ ...prev, [uuid]: sent }));
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setStreamedAnswers([]);
        setIsLoading(false);
        setIsStreaming(false);
        dispatch(clearChatAction());
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [streamedAnswers]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onRetrievalModeChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<RetrievalMode> | undefined, index?: number | undefined) => {
        setRetrievalMode(option?.data || RetrievalMode.Hybrid);
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onShouldStreamChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setShouldStream(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onUseOidSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseOidSecurityFilter(!!checked);
    };

    const onUseGroupsSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseGroupsSecurityFilter(!!checked);
    };

    // const onExampleClicked = (example: string) => {
    //     makeApiRequest(example);
    // };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

        setSelectedAnswer(index);
    };

    const onCitationClicked = useCallback(
        (citation: string, index: number) => {
            onShowCitation(citation, index);
        },
        [onShowCitation]
    );

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const onAnalysisPanelIsOpen = () => {
        if (analysisPanelIsOpen) {
            return;
        } else {
            setAnalysisPanelIsOpen(true);
        }
    };

    const onAnalysisPanelIsClose = () => {
        setAnalysisPanelIsOpen(false);
    };

    const adminGroupId = import.meta.env.VITE_REACT_APP_ADMIN_SECURITY_GROUP_ID;
    const commonGroupId = import.meta.env.VITE_REACT_APP_COMMON_SECURITY_GROUP_ID;
    const userGroups = activeAccount?.idTokenClaims?.groups || [];
    const isAuthGroupIdInGroups = Array.isArray(userGroups) ? userGroups.includes(adminGroupId) : false;
    const isCommonGroupIdInGroups = Array.isArray(userGroups) ? userGroups.includes(commonGroupId) : false;

    if (activeAccount && !isCommonGroupIdInGroups && activeAccount && !isAuthGroupIdInGroups) {
        return (
            <div className={styles.deniedContainer}>
                <h1 className={styles.title}>Kyoden Brain</h1>
                <div className={styles.loginMessageContainer}>
                    <p>このリソースを表示するのに十分な権限がありません。</p>
                </div>
            </div>
        );
    }

    const renderStreamedAnswers = () => {
        const latestAnswerUuid = latestAnswerId;
        return streamedAnswers
            .map((streamedAnswer, index) => {
                if (streamedAnswer[2] !== latestAnswerUuid || !isStreaming) {
                    return (
                        <div key={index}>
                            <div key={index}>
                                <UserChatMessage message={streamedAnswer[0]} userName={userName || ""} />
                                <div className={styles.chatMessageGpt}>
                                    <Answer
                                        approach="chat"
                                        isStreaming={isStreaming}
                                        key={index}
                                        answer={streamedAnswer[1]}
                                        isSelected={false}
                                        onCitationClicked={c => onCitationClicked(c, index)}
                                        onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                        onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                        onFollowupQuestionClicked={q => makeApiRequest(q)}
                                        showFollowupQuestions={useSuggestFollowupQuestions && chats.length - 1 === index}
                                        openai_uuid={streamedAnswer[2]}
                                        updateFeedbackSentStatus={function (uuid: string, sent: boolean): void {
                                            throw new Error("Function not implemented.");
                                        }}
                                        isLatestAnswer={true}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                }
                return null;
            })
            .filter(Boolean);
    };

    const renderChatMessages = () => {
        return chats.map((chat, index) => (
            <div key={index}>
                <UserChatMessage message={chat[0]} userName={userName || ""} />
                <div className={styles.chatMessageGpt}>
                    <Answer
                        approach="chat"
                        isStreaming={isStreaming}
                        key={index}
                        answer={chat[1]}
                        openai_uuid={chat[2]}
                        isSelected={false}
                        onCitationClicked={c => onCitationClicked(c, index)}
                        onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                        onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                        onFollowupQuestionClicked={q => makeApiRequest(q)}
                        showFollowupQuestions={useSuggestFollowupQuestions && chats.length - 1 === index}
                        onAnalysisPanelIsOpen={onAnalysisPanelIsOpen}
                        // onAnalysisPanelIsClose={onAnalysisPanelIsClose}
                        isFeedbackSent={feedbackSentStatus[chat[2]]}
                        updateFeedbackSentStatus={updateFeedbackSentStatus}
                        isLatestAnswer={false}
                    />
                </div>
            </div>
        ));
    };

    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                <ReferenceIndexes referenceIndex={referenceIndex} setReferenceIndex={setReferenceIndex} referenceIndexesItems={referenceIndexesItems} disabled={isLoading}/>
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={isLoading} />
                {/* <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} /> */}
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!isLoading && !isStreaming && chats.length == 0 && (
                        <div className={styles.chatEmptyState}>
                            <div className={styles.imgContainer}>
                                <img src="../../assets/kyoden-aoai-char.png" alt="Kyo-Kun logo" />
                            </div>
                            <h1 className={styles.chatEmptyStateTitle}>
                                <small className={styles.chatEmptyStateSubTitle}>Kyoden Brain</small>
                                <br />
                                Kyo-kun
                            </h1>
                            <h2 className={styles.chatEmptyStateSubtitle}>社内規定について何でも尋ねてください。</h2>
                        </div>
                    )}
                    <div className={styles.chatMessageStream}>
                        {renderChatMessages()}
                        {isStreaming && renderStreamedAnswers()}
                        {isLoading && (
                            <>
                                <UserChatMessage message={lastQuestionRef.current} userName={userName || ""} />
                                <div className={styles.chatMessageGptMinWidth}>
                                    <AnswerLoading approach="chat" />
                                </div>
                            </>
                        )}
                        {error ? (
                            <>
                                <UserChatMessage message={lastQuestionRef.current} userName={userName || ""} />
                                <div className={styles.chatMessageGptMinWidth}>
                                    <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                </div>
                            </>
                        ) : null}
                        <div ref={chatMessageStreamEnd} />
                    </div>
                    <div className={styles.chatInput}>
                        {!activeAccount && (
                            <div className={styles.loginMessageContainer}>
                                <p className={styles.loginMessage}>質問を入力するにはログインしてください。</p>
                                <LoginButton />
                            </div>
                        )}
                        {activeAccount && errorOrLoading && (
                            <div className={styles.LoadingContainer}>
                                <Loader />
                                <p className={styles.LoadingMessage}>キョウくんを起動しています。少々お待ちください...</p>
                            </div>
                        )}
                        {activeAccount && !errorOrLoading && (
                            <>
                                {isExampleListOpen && (
                                    <ExampleList onSend={makeApiRequest} disabled={isLoading} usedTokens={usedTokens} isStreaming={isStreaming} />
                                )}
                            </>
                        )}
                    </div>
                </div>

                {chats.length > 0 && activeAnalysisPanelTab && analysisPanelIsOpen && (
                    <AnalysisPanel
                        activeCitation={activeCitation}
                        citationHeight="90%"
                        onAnalysisPanelIsOpen={onAnalysisPanelIsOpen}
                        onAnalysisPanelIsClose={onAnalysisPanelIsClose}
                        onFollowupQuestionClicked={q => makeApiRequest(q)}
                    />
                )}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many search results:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                    {useLogin && (
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useOidSecurityFilter}
                            label="Use oid security filter"
                            disabled={!client?.getActiveAccount()}
                            onChange={onUseOidSecurityFilterChange}
                        />
                    )}
                    {useLogin && (
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useGroupsSecurityFilter}
                            label="Use groups security filter"
                            disabled={!client?.getActiveAccount()}
                            onChange={onUseGroupsSecurityFilterChange}
                        />
                    )}
                    <Dropdown
                        className={styles.chatSettingsSeparator}
                        label="Retrieval mode"
                        options={[
                            { key: "hybrid", text: "Vectors + Text (Hybrid)", selected: retrievalMode == RetrievalMode.Hybrid, data: RetrievalMode.Hybrid },
                            { key: "vectors", text: "Vectors", selected: retrievalMode == RetrievalMode.Vectors, data: RetrievalMode.Vectors },
                            { key: "text", text: "Text", selected: retrievalMode == RetrievalMode.Text, data: RetrievalMode.Text }
                        ]}
                        required
                        onChange={onRetrievalModeChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={shouldStream}
                        label="Stream chat completion responses"
                        onChange={onShouldStreamChange}
                    />
                    {useLogin && <TokenClaimsDisplay />}
                </Panel>
            </div>
        </div>
    );
};

export default Chat;
