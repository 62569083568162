import { useRef, useState, useEffect } from "react";
import { Dialog, DialogSurface, DialogTitle, DialogBody, DialogActions, DialogContent, Button } from "@fluentui/react-components";
import readNDJSONStream from "ndjson-readablestream";

import styles from "./ChatGpt.module.css";

import {
    chatGPTApi,
    getHeaders,
    RetrievalMode,
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppRequest,
    ResponseMessage,
    VectorFieldOptions,
    GPT4VInput
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";

import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { ClearChatButton } from "../../components/ClearChatButton";
import { useLogin, getToken } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { LoginButton } from "../../components/LoginButton";
import useSWR, { mutate } from "swr";
import Loader from "../../components/Loader/Loader";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { addChatGpt, clearChatGpt } from "../../redux/slice/chatGptSlice";
import { UsedTokens } from "../../components/UsedTokens/UsedTokens";
import GptDeploymentSwitcher from "../../components/GptDeploymentSwitcher/GptDeploymentSwitcher";

const ChatGPT = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const userName = activeAccount ? activeAccount.name : null;

    const dispatch = useDispatch();
    const chatGpts = useSelector((state: RootState) => state.chatGpt);

    const [temperature, setTemperature] = useState<number>(0.3);
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [shouldStream, setShouldStream] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [vectorFieldList, setVectorFieldList] = useState<VectorFieldOptions[]>([VectorFieldOptions.Embedding]);
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);
    const [useOidSecurityFilter, setUseOidSecurityFilter] = useState<boolean>(false);
    const [useGroupsSecurityFilter, setUseGroupsSecurityFilter] = useState<boolean>(false);
    const [gpt4vInput, setGPT4VInput] = useState<GPT4VInput>(GPT4VInput.TextAndImages);
    const [gptDeployment, setGptDeployment] = useState<string>("gpt-4o");

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [latestAnswerId, setLatestAnswerId] = useState<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse, uuid: string, imagePreviewUrl: string | null][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse, uuid: string, imagePreviewUrl: string | null][]>([]);

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);

    const gptDeploymentItems = [
        { id: "GPT-4o", gptDeploymentName: "gpt-4o" }
    ];

    const handleAsyncRequest = async (question: string, answers: [string, ChatAppResponse, string, string | null][], responseBody: ReadableStream<any>) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;
        let openai_uuid: string = "";

        const updateState = (newContent: string) => {
            return new Promise(resolve => {
                setTimeout(() => {
                    answer += newContent;
                    const latestResponse: ChatAppResponse = {
                        ...askResponse,
                        message: { content: answer, role: askResponse.message.role }
                    };
                    setStreamedAnswers([...answers, [question, latestResponse, openai_uuid, imagePreviewUrl]]);
                    resolve(null);
                }, 22);
            });
        };

        try {
            setIsStreaming(true);
            setIsUploading(false);
            for await (const event of readNDJSONStream(responseBody)) {
                if (event["context"] && event["context"]["content"]) {
                    event["message"] = event["delta"];
                    askResponse = event;
                    openai_uuid = event["id"];
                } else if (event["delta"]["content"]) {
                    openai_uuid = event["id"];
                    setIsLoading(false);
                    await updateState(event["delta"]["content"]);
                } else if (event["context"]) {
                    openai_uuid = event["id"];
                    // Update context with new keys from latest event
                    askResponse.context = { ...askResponse.context, ...event["context"] };
                } else if (event && event["error"]) {
                    throw Error(event["error"]);
                }
            }
        } finally {
            setIsStreaming(false);
        }
        const fullResponse: [string, ChatAppResponse, string, string | null] = [
            question,
            {
                ...askResponse,
                message: { content: answer, role: askResponse.message.role }
            },
            openai_uuid,
            imagePreviewUrl
        ];

        return fullResponse;
    };

    const client = useLogin ? useMsal().instance : undefined;

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setIsUploading(!!selectedImage); // Set isUploading to true if there is an image
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setStreamedAnswers([]);

        const token = client ? await getToken(client) : undefined;

        try {
            const messages: ResponseMessage[] = answers.flatMap(a => [
                { content: a[0], role: "user" },
                { content: a[1].message.content, role: "assistant" }
            ]);

            const request: ChatAppRequest = {
                index_name: "",
                gpt_deployment: gptDeployment,
                messages: [...messages, { content: question, role: "user" }],
                stream: shouldStream,
                context: {
                    overrides: {
                        prompt_template: "undefined",
                        exclude_category: excludeCategory.length === 0 ? "undefined" : excludeCategory,
                        top: retrieveCount,
                        temperature: temperature,
                        retrieval_mode: retrievalMode,
                        semantic_ranker: useSemanticRanker,
                        semantic_captions: useSemanticCaptions,
                        suggest_followup_questions: false,
                        use_oid_security_filter: useOidSecurityFilter,
                        use_groups_security_filter: useGroupsSecurityFilter,
                        vector_fields: vectorFieldList,
                        use_gpt4v: false,
                        gpt4v_input: gpt4vInput
                    }
                },
                session_state: chatGpts.length === 0 ? false : true
            };

            if (selectedImage) {
                const reader = new FileReader();
                reader.onloadend = async () => {
                    const base64Image = reader.result?.toString().split(',')[1];
                    if (!base64Image) {
                        throw new Error("Failed to encode image to Base64");
                    }

                    request.image = {
                        data: base64Image,
                        mime_type: selectedImage.type
                    };

                    const response = await chatGPTApi(request, token?.accessToken);
                    handleResponse(response, question, answers);
                };
                reader.readAsDataURL(await resizeImage(selectedImage, 800, 800));
            } else {
                const response = await chatGPTApi(request, token?.accessToken);
                handleResponse(response, question, answers);
            }

            setSelectedImage(null);
            setImagePreviewUrl(null);
        } catch (e) {
            setError(e);
            console.error(e);
        } finally {
            setIsLoading(false);
            mutate("/getusedtoken");
        }
    };

    const handleResponse = async (response: Response, question: string, answers: [string, ChatAppResponse, string, string | null][]) => {
        if (!response.body) {
            throw Error("No response body");
        }
        if (shouldStream) {
            const [q, parsedResponse, uuid] = await handleAsyncRequest(question, answers, response.body);
            setAnswers([...answers, [q, parsedResponse, uuid, imagePreviewUrl]]);
            dispatch(addChatGpt([q, parsedResponse, uuid, imagePreviewUrl]));
            setLatestAnswerId(uuid);
        } else {
            const parsedResponse: ChatAppResponseOrError = await response.json();
            if (response.status > 299 || !response.ok) {
                throw Error(parsedResponse.error || "Unknown error");
            }
            const uuid = parsedResponse.choices?.[0]?.openai_uuid ?? "";
            setLatestAnswerId(uuid);

            if (parsedResponse.choices && parsedResponse.choices.length > 0) {
                dispatch(addChatGpt([question, parsedResponse as ChatAppResponse, uuid, imagePreviewUrl]));
                setAnswers([...answers, [question, parsedResponse as ChatAppResponse, uuid, imagePreviewUrl]]);
            }
        }
    };

    const fetcher = async () => {
        const token = client ? await getToken(client) : undefined;
        if (token == undefined) {
            throw new Error("Token is undefined");
        } else {
            return fetch("/getusedtoken", {
                method: "GET",
                headers: getHeaders(token ? token.accessToken : undefined)
            }).then(res => res.json());
        }
    };

    const { data: usedTokens, error: gettingTokenError, isLoading: gettingToken = true } = useSWR("/getusedtoken", fetcher);
    const errorOrLoading = gettingToken || gettingTokenError;

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setStreamedAnswers([]);
        setIsLoading(false);
        setIsStreaming(false);
        dispatch(clearChatGpt());
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [streamedAnswers]);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedImage(file);
            setImagePreviewUrl(URL.createObjectURL(file));
            setIsDialogOpen(false);
        }
    };

    const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<File> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const canvas = document.createElement("canvas");
            const reader = new FileReader();

            reader.onload = (e) => {
                img.src = e.target?.result as string;
            };

            img.onload = () => {
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height *= maxWidth / width));
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width *= maxHeight / height));
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                canvas.getContext("2d")?.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(new File([blob], file.name, { type: file.type }));
                    } else {
                        reject(new Error("Canvas is empty"));
                    }
                }, file.type);
            };

            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const adminGroupId = import.meta.env.VITE_REACT_APP_ADMIN_SECURITY_GROUP_ID;
    const commonGroupId = import.meta.env.VITE_REACT_APP_COMMON_SECURITY_GROUP_ID;
    const userGroups = activeAccount?.idTokenClaims?.groups || [];
    const isAuthGroupIdInGroups = Array.isArray(userGroups) ? userGroups.includes(adminGroupId) : false;
    const isCommonGroupIdInGroups = Array.isArray(userGroups) ? userGroups.includes(commonGroupId) : false;

    if (activeAccount && !isCommonGroupIdInGroups && activeAccount && !isAuthGroupIdInGroups) {
        return (
            <div className={styles.deniedContainer}>
                <h1 className={styles.title}>Chatlog</h1>
                <div className={styles.loginMessageContainer}>
                    <p>このリソースを表示するのに十分な権限がありません。</p>
                </div>
            </div>
        );
    }

    const renderStreamedAnswers = () => {
        const latestAnswerUuid = latestAnswerId;
        return streamedAnswers
            .map((streamedAnswer, index) => {
                if (streamedAnswer[2] !== latestAnswerUuid || !isStreaming) {
                    return (
                        <div key={index}>
                            <UserChatMessage message={streamedAnswer[0]} userName={userName || ""} imagePreviewUrl={streamedAnswer[3]} isUploading={isUploading} />
                            <div key={index}>
                                <div className={styles.chatMessageGpt}>
                                    <Answer
                                        approach="chatgpt"
                                        isStreaming={isStreaming}
                                        key={index}
                                        answer={streamedAnswer[1]}
                                        isSelected={false}
                                        onCitationClicked={c => onShowCitation(c, index)}
                                        onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                        onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                        onFollowupQuestionClicked={q => makeApiRequest(q)}
                                        showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                        openai_uuid={streamedAnswer[2]}
                                        updateFeedbackSentStatus={function (uuid: string, sent: boolean): void {
                                            throw new Error("Function not implemented.");
                                        }}
                                        isLatestAnswer={true}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                }
                return null;
            })
            .filter(Boolean);
    };

    const renderChatGptMessages = () => {
        return chatGpts.map((chatGpt, index) => (
            <div key={index}>
                <UserChatMessage message={chatGpt[0]} userName={userName || ""} imagePreviewUrl={chatGpt[3]} isUploading={isUploading} />
                <div className={styles.chatMessageGpt}>
                    <div className={styles.chatMessageGpt}>
                        <Answer
                            approach="chatgpt"
                            isStreaming={isStreaming}
                            key={index}
                            answer={chatGpt[1]}
                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                            onCitationClicked={c => onShowCitation(c, index)}
                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                            openai_uuid={chatGpt[2]}
                            updateFeedbackSentStatus={function (uuid: string, sent: boolean): void {
                                throw new Error("Function not implemented.");
                            }}
                            isLatestAnswer={false}
                        />
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                <GptDeploymentSwitcher
                    gptDeployment={gptDeployment}
                    setGptDeployment={setGptDeployment}
                    gptDeploymentItems={gptDeploymentItems}
                    disabled={false}
                />
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={isLoading} />
                {/* <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} /> */}
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!isLoading && !isStreaming && chatGpts.length == 0 && (
                        <div className={styles.chatEmptyState}>
                            <div className={styles.opanaiLogoContainer}>
                                <img src="../../assets/openai-logomark.png" className={styles.openaiLogo} />
                            </div>
                            <h1 className={styles.chatEmptyStateTitle}>ChatGPT</h1>
                            <h2 className={styles.chatEmptyStateSubtitle}>
                                {gptDeploymentItems.map(item => (item.gptDeploymentName == gptDeployment ? item.id : null))}
                            </h2>
                        </div>
                    )}
                    <div className={styles.chatMessageStream}>
                        {renderChatGptMessages()}
                        {isStreaming && renderStreamedAnswers()}
                        {isUploading && (
                            <UserChatMessage
                                message="画像アップロード中です..."
                                userName={userName || ""}
                                imagePreviewUrl={imagePreviewUrl}
                                isUploading={isUploading}
                            />
                        )}
                        {isLoading && (
                            <>
                                <UserChatMessage message={lastQuestionRef.current} userName={userName || ""} />
                                <div className={styles.chatMessageGptMinWidth}>
                                    <AnswerLoading approach="chatgpt" />
                                </div>
                            </>
                        )}
                        {error ? (
                            <>
                                <UserChatMessage message={lastQuestionRef.current} userName={userName || ""} />
                                <div className={styles.chatMessageGptMinWidth}>
                                    <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                </div>
                            </>
                        ) : null}
                        <div ref={chatMessageStreamEnd} />
                    </div>
                    <div className={styles.chatInput}>
                        {activeAccount ? (
                            errorOrLoading ? (
                                <div className={styles.LoadingContainer}>
                                    <Loader />
                                    <p className={styles.LoadingMessage}>Chat GPT を起動しています...</p>
                                </div>
                            ) : (
                                <>
                                    <UsedTokens usedTokens={usedTokens} />
                                    {usedTokens <= 50000 && (
                                        <QuestionInput
                                            usedTokens={usedTokens}
                                            clearOnSend
                                            placeholder="質問を入力してください。"
                                            disabled={isLoading}
                                            onSend={question => makeApiRequest(question)}
                                            isStreaming={isStreaming}
                                            gptDeployment={gptDeployment}
                                            onUploadImageClick={() => setIsDialogOpen(true)}
                                            selectedImage={selectedImage}
                                            imagePreviewUrl={imagePreviewUrl}
                                            onRemoveImage={() => {
                                                setSelectedImage(null);
                                                setImagePreviewUrl(null);
                                            }}
                                        />
                                    )}
                                </>
                            )
                        ) : (
                            <div className={styles.loginMessageContainer}>
                                <p className={styles.loginMessage}>質問を入力するにはログインしてください。</p>
                                <LoginButton />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Dialog open={isDialogOpen} onOpenChange={() => setIsDialogOpen(!isDialogOpen)}>
                <DialogSurface style={{ backgroundColor: "rgba(255, 255, 255, 1)", color: "black", borderRadius: "10px" }}>
                    <DialogBody>
                        <DialogTitle style={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>画像アップロード</DialogTitle>
                        <DialogContent>
                            <input type="file" accept="image/*" onChange={onFileChange} />
                        </DialogContent>
                        <DialogActions>
                            <Button appearance="secondary" onClick={() => setIsDialogOpen(false)}>
                                キャンセル
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    );
};

export default ChatGPT;
